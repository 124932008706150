import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import './App.css';
import Question from '../question/Question';
import Survey from '../survey/Survey';
import QuestionData from '../../data/QuestionData';
import AnswerData from '../../data/AnswerData';

export default function App() {
  return (
    <Router>
      <div className="App">
        {/* <nav className="navigation">
          <ul>
            <li>
              <Link to="/">Splash</Link>
            </li>
            <li>
              <Link to="/Rules">Rules</Link>
            </li>
            <li>
              <Link to="/Quiz">Quiz</Link>
            </li>
          </ul>
        </nav> */}

        <Switch>
          <Route path="/Quiz">
            <Quiz />
          </Route>
          <Route path="/Rules">
            <Rules />
          </Route>
          <Route path="/">
            <Splash />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function Splash() {
  return (
    <div className="splash-screen">
      <span className="be-inspired"></span>
      <Link className="splash-link" to="/Rules"></Link>
    </div>
  );
}

function Quiz() {
  return (
    <div className="survey">
      <Survey questions = {
                getQuestions(10)
            }/>
    </div>
    );
}

function getQuestions(num){
  var allQuestions = [
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Сеул",false), new AnswerData("Токио",true), new AnswerData("Истанбул",false)], "tokyo"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Дубаи",true), new AnswerData("Москва",false), new AnswerData("Париз",false)], "dubai"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Лондон",false), new AnswerData("Милано",false), new AnswerData("Њу Јорк",true)], "ny"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Милано",false), new AnswerData("Истанбул",true), new AnswerData("Москва",false)], "istanbul"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Париз",false), new AnswerData("Сеул",true), new AnswerData("Москва",false)], "seul"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Сеул",false), new AnswerData("Истанбул",true), new AnswerData("Хонг Конг",false)], "istanbul"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Париз",true), new AnswerData("Сеул",false), new AnswerData("Москва",false)], "paris"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Хонг Конг",true), new AnswerData("Москва",false), new AnswerData("Њу Јорк",false)], "hk"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Анкара",false), new AnswerData("Берлин",true), new AnswerData("Рим",false)], "berlin"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Монако",true), new AnswerData("Сеул",false), new AnswerData("Москва",false)], "monako"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Сеул",true), new AnswerData("Берлин",false), new AnswerData("Рим",false)], "seul"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Сеул",false), new AnswerData("Токио",true), new AnswerData("Истанбул",false)], "tokyo2"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Дубаи",true), new AnswerData("Москва",false), new AnswerData("Париз",false)], "dubai2"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Лондон",false), new AnswerData("Милано",false), new AnswerData("Њу Јорк",true)], "ny2"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Милано",false), new AnswerData("Истанбул",true), new AnswerData("Москва",false)], "istanbul2"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Париз",false), new AnswerData("Сеул",true), new AnswerData("Москва",false)], "seul2"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Сеул",false), new AnswerData("Истанбул",true), new AnswerData("Хонг Конг",false)], "istanbul2"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Париз",true), new AnswerData("Сеул",false), new AnswerData("Москва",false)], "paris2"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Хонг Конг",true), new AnswerData("Москва",false), new AnswerData("Њу Јорк",false)], "hk2"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Токио",false), new AnswerData("Берлин",true), new AnswerData("Рим",false)], "berlin2"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Монако",true), new AnswerData("Сеул",false), new AnswerData("Москва",false)], "monako2"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Сеул",true), new AnswerData("Берлин",false), new AnswerData("Рим",false)], "seul2"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Сеул",false), new AnswerData("Токио",true), new AnswerData("Истанбул",false)], "tokyo3"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Дубаи",true), new AnswerData("Москва",false), new AnswerData("Париз",false)], "dubai3"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Лондон",false), new AnswerData("Анкара",false), new AnswerData("Њу Јорк",true)], "ny3"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Милано",false), new AnswerData("Истанбул",true), new AnswerData("Москва",false)], "istanbul3"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Париз",false), new AnswerData("Сеул",true), new AnswerData("Москва",false)], "seul3"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Сеул",false), new AnswerData("Истанбул",true), new AnswerData("Хонг Конг",false)], "istanbul3"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Париз",true), new AnswerData("Сеул",false), new AnswerData("Москва",false)], "paris3"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Хонг Конг",true), new AnswerData("Москва",false), new AnswerData("Њу Јорк",false)], "hk3"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Токио",false), new AnswerData("Берлин",true), new AnswerData("Рим",false)], "berlin3"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Монако",true), new AnswerData("Сеул",false), new AnswerData("Москва",false)], "monako3"),
    new QuestionData("Која светска метропола е прикажана на сликата?", [new AnswerData("Сеул",true), new AnswerData("Берлин",false), new AnswerData("Рим",false)], "seul3"),

]

  var newQuestions = [];
  while(num > 0){
    newQuestions.push(allQuestions.splice(Math.floor(Math.random() * allQuestions.length), 1)[0]);
    num--;
  }

  return newQuestions;
}

function Rules() {
  return (
  <div className="row rules-of-game">
          <Link className="splash-link" to="/Quiz"></Link>
    <span className="the-rules">
    Ги наградуваме оние со космополитски дух и знаење!
<br /><br />

Ќе Ви биде прикажана фотографија од 10 различни
светски метрополи и повеќе можни одговори за секоја
фотографија.
<br /><br />

Доколку го препознаете и го погодите градот на
фотографијата, освојувате поени.
<br /><br />

Повеќе поени, повредна награда!
<br /><br />

<b>Среќно!</b>
    </span>
  </div>
  );
}


